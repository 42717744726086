<template>
    <div class="flex justify-center items-center">
        <form class="space-y-6 loginCardWidth min-h-[250px] flex flex-col justify-center"
            @submit.prevent="handleSubmit()" autocomplete="off">
            <div>
                <!-- <p class="text-[20px] font-bold primary-color mb-5">Reset Password</p> -->
                <label :for="`${stage}_input_1`" class="block text-sm font-medium primary-color">Create Password</label>

                <div class="mt-1 flex loginRadius shadow-sm">
                    <div class="relative flex flex-grow items-stretch focus-within:z-10">
                        <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-[6px]">
                            <span class="bg-[#F4F0FC] loginRadius p-2">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="currentColor" class="w-4 h-4 violet-color">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
                                </svg>
                            </span>
                        </div>
                        <!-- <input ref="createPasswordReset" type="password" v-model="createPasswordReset" name="createPasswordReset"
                        :id="`${stage}_input_1`" autocomplete="off"
                        class="text-base block w-full loginRadius border-gray-200 pl-12 leading-6 " placeholder="Password"
                        :class="{ 'is-invalid': submitted && !createPasswordReset }" @input="resetError()"> -->

                        <div class="relative flex flex-grow items-stretch focus-within:z-10">
                            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-[6px] ">
                                <span class="bg-[#F4F0FC] loginRadius p-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                        stroke-width="1.5" stroke="currentColor" class="w-4 h-4 violet-color">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                            d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z" />
                                    </svg>
                                </span>
                            </div>
                            <input maxlength="16" ref="createPasswordReset"
                                :type="passHide == 'Hide' ? 'password' : 'text'" v-model="createPasswordReset"
                                name="createPasswordReset" :id="`${stage}_input`" autocomplete="off"
                                class="text-sm block w-full loginRadius border-gray-200 pl-12 leading-6"
                                placeholder="Password" :class="{ 'is-invalid': submitted && !createPasswordReset }"
                                @input="resetError(); passStrengthCheck()" @keypress="$common.preventSpace($event)">
                            <div @click="hideShow()"
                                class="absolute inset-y-0 right-3 flex items-center pl-3 cursor-pointer z-50 opacity-90">
                                <svg v-if="passHide == 'Show'" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>
                                <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-2 flex w-[268px]">
                    <div class="w-1/5 px-1" v-for="(item, index) in passwordtab" :key="index">
                        <div class="h-1 rounded-xl"
                            :class="index < this.passwordScore ? (this.passwordScore == 1 ? 'bg-red-300' : this.passwordScore == 2 ? 'bg-amber-400' : this.passwordScore == 3 ? 'bg-orange-400' : this.passwordScore == 4 ? 'bg-yellow-400' : this.passwordScore == 5 ? 'bg-green-400' : 'bg-gray-200') : 'bg-gray-200'">
                        </div>
                    </div>
                </div>

                <!-- confirm password -->
                <label :for="`${stage}_input_2`" class="block text-sm font-medium primary-color mt-4">Confirm
                    Password</label>
                <div class="mt-1 flex loginRadius-md shadow-sm">
                    <div class="relative flex flex-grow items-stretch focus-within:z-10">
                        <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-[6px]">
                            <span class="bg-[#F4F0FC] loginRadius p-2">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="currentColor" class="w-4 h-4 violet-color">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
                                </svg>
                            </span>
                        </div>
                        <!-- <input ref="confirmPasswordReset" type="text" v-model="confirmPasswordReset" name="confirmPasswordReset"
                        :id="`${stage}_input_2`" autocomplete="off"
                        class="text-base block w-full loginRadius  border-gray-200 pl-12 leading-6 "
                        placeholder="Confirm Password" :class="{ 'is-invalid': submitted && !confirmPasswordReset }"
                        @input="resetError()"> -->

                        <div class="relative flex flex-grow items-stretch focus-within:z-10">
                            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-[6px] ">
                                <span class="bg-[#F4F0FC] loginRadius p-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                        stroke-width="1.5" stroke="currentColor" class="w-4 h-4 violet-color">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                            d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z" />
                                    </svg>
                                </span>
                            </div>
                            <input maxlength="16" ref="confirmPasswordReset"
                                :type="passHide1 == 'Hide' ? 'password' : 'text'" v-model="confirmPasswordReset"
                                name="confirmPasswordReset" :id="`${stage}_input_1`" autocomplete="off"
                                class="text-sm block w-full loginRadius border-gray-200 pl-12 leading-6"
                                placeholder="Confirm Password"
                                :class="{ 'is-invalid': submitted && !confirmPasswordReset }" @input="resetError()"
                                @keypress="$common.preventSpace($event)">
                            <div @click="hideShow1()"
                                class="absolute inset-y-0 right-3 flex items-center pl-3 cursor-pointer z-50 opacity-90">
                                <svg v-if="passHide1 == 'Show'" xmlns="http://www.w3.org/2000/svg" fill="none"
                                    viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>
                                <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- password policy -->
                <div class="text-[12px] pt-2 secondary-color">
                    <span>Your Password must have</span>
                    <span class="mb-2" :class="eight_char ? 'text-[#00A03C]' : 'secondary-color'">
                        8 to 16 characters,</span>
                    <span class="mb-2" :class="contains_number ? 'text-[#00A03C]' : 'secondary-color'
                    "> 1 number,</span>
                    <span class="mb-2" :class="contains_lowercase
                        ? 'text-[#00A03C]'
                        : 'secondary-color'
                    "> 1 uppercase letter,</span>
                    <span class="mb-2" :class="contains_lowercase
                        ? 'text-[#00A03C]'
                        : 'secondary-color'
                    "> 1 lowercase letter </span>and
                    <span class="mb-2" :class="contains_special_char ? 'text-[#00A03C]' : 'secondary-color'">
                        1 Special character</span>
                </div>


            </div>
            <div>
                <button :id="`${stage}_submit_btn`" :disabled="loader" type="submit" :class="{ 'loading': loader }"
                    class="flex w-full justify-center loginRadius border 
                                                    border-transparent violet-bg py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-[#6f23f2] 
                                                    focus:outline-none disabled:bg-gray-100 disabled:primary-color disabled:cursor-not-allowed min-h-[38px]">
                    <span v-if="!loader">Next</span>
                    <svg v-if="loader" aria-hidden="true" role="status"
                        class="inline mr-3 w-5 h-5 secondary-color animate-spin" viewBox="0 0 100 101" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="#E5E7EB"></path>
                        <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentColor"></path>
                    </svg>
                </button>

                <div class="flex mt-2 text-xs justify-center">
                    <div class="text-center">Back to <a
                            class="font-medium cursor-pointer hyperLink hover:text-blue-500 opacity-70"
                            @click="backToLogin()">Login</a></div>
                </div>


                <div class="h-2 mt-4">
                    <loginmessage cls="error text-left" v-show="!loader && submitted">
                        {{ !createPasswordReset ? 'Password is required' : errorMessage ? errorMessage : '' }}
                    </loginmessage>
                </div>
            </div>
        </form>
    </div>
</template>


<script>
import { mapActions, mapGetters } from "vuex";
export default {
    data() {
        return {
            createPasswordReset: '',
            confirmPasswordReset: '',
            passHide: 'Hide',
            passHide1: 'Hide',
            passwordtab: [1, 2, 3, 4, 5],
            contains_number: false,
            contains_uppercase: false,
            contains_lowercase: false,
            eight_char: false,
            contains_special_char: false,
            passwordScore: 0,
        }
    },
    methods: {
        async handleSubmit() {
            this.$store.commit('login/setSubmitted', true)
            if (this.stage == 'resetPass') {
                if (this.confirmPasswordReset == this.createPasswordReset && this.confirmPasswordReset && this.checkPwd(this.confirmPasswordReset) == 'ok') {
                    if (this.stage == 'resetPass' && !this.loader) {
                        await this.$store.dispatch('login/resetPassword', { password: this.confirmPasswordReset })
                    }
                } else if (this.confirmPasswordReset == '') {
                    this.$store.commit('login/setErrorMessage', 'Confirm Password is required')
                    return false
                } else if (this.createPasswordReset != this.confirmPasswordReset) {
                    this.$store.commit('login/setErrorMessage', 'Password does not match.')
                    return false
                } else if (this.checkPwd(this.confirmPasswordReset) != 'ok') {
                    this.$store.commit('login/setErrorMessage', this.checkPwd(this.confirmPasswordReset))
                }
            }
        },
        checkPwd(str) {
            if (str.length < 8) {
                return "Password must contain min 8 characters";
            } else if (str.length > 16) {
                return "Password must contain max 16 characters";
            } else if (str?.search(/\d/) == -1) {
                return "Password must contain 1 number";
            } else if (str?.search(/[a-zA-Z]/) == -1) {
                return "Password must contain 1 letter";
            } else if(!( /[a-z]/.test(str))) {
                return "Password must contain 1 lowercase";
            } else if(!(/[A-Z]/.test(str))) {
                return "Password must contain 1 uppercase";
            } else if(!(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(str))) {
                return "Password must contain 1 special character";
            }
            return "ok";
        },
        resetInputFields() {
            this.panNumber = '',
            this.mobileNoForgot = ''
            this.passwordScore = 0
            this.contains_number= false,
            this.contains_uppercase= false,
            this.contains_lowercase= false,
            this.eight_char= false,
            this.contains_special_char= false
        },
        resetError() {
            this.$store.commit('login/setErrorMessage', null)
            this.$store.commit('login/setSubmitted', false)
        },
        hideShow() {
            this.passHide = this.passHide == 'Show' ? 'Hide' : 'Show'
        },
        hideShow1() {
            this.passHide1 = this.passHide1 == 'Show' ? 'Hide' : 'Show'
        },
        backToLogin() {
            this.$store.dispatch('login/backToLogin');
            this.resetInputFields();
            this.resetError();
            this.passHide = 'Hide'
            this.passHide1 = 'Hide'
        },
        passStrengthCheck() {
        let NUMBER = /\d/;
        let UPPERCASE = /[A-Z]/;
        let SPECIAL_CHAR = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
        let LOWERCASE = /[a-z]/;
  
        this.eight_char = this.createPasswordReset.length >= 8;
        this.contains_number = NUMBER.test(this.createPasswordReset);
        this.contains_uppercase = UPPERCASE.test(this.createPasswordReset);
        this.contains_special_char = SPECIAL_CHAR.test(this.createPasswordReset);
        this.contains_lowercase = LOWERCASE.test(this.createPasswordReset);
  
        // check password is valid
        let arr = [];
        if (this.contains_uppercase) {
          arr.push("1");
        }
        if (this.contains_number) {
          arr.push("2");
        }
        if(this.contains_special_char){
          arr.push('5')
        }
        if (this.contains_lowercase) {
          arr.push("3");
        }
        if (this.eight_char) {
          arr.push("4");
        }
        this.passwordScore = arr?.length;
      },
    },
    computed: {
        ...mapGetters('login', ["stage", "loader", "submitted", "currentMobileNo", "errorMessage"])
    },
    created() {
        this.$nextTick(() => {
            if (this.$refs.createPasswordReset) {
                this.$refs.createPasswordReset.focus();
            }
        });
    },
}
</script>