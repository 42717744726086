<template>
    <div class="flex justify-center items-center">
        <form class="space-y-6 loginCardWidth min-h-[250px] flex flex-col justify-center" @submit.prevent="handleSubmit()"
            autocomplete="off">
            <div>
                <div>
                <div class="flex justify-between items-center gap-2 mb-4">
                    <div class="flex items-center space-x-[10px]">
                        <span class="inline-flex min-h-[49px] min-w-[49px] items-center border-2 border-[#753ed7] justify-center rounded-full bg-[#F4F0FC]">
                            <span class="text-xl leading-none text-[#753ed7] capitalize">
                                {{ $store.state.login?.userName?.charAt(0) ?? $store.state.login?.UCC?.charAt(0) }}
                            </span>
                        </span>
                        <div>
                            <p class="primary-color !font-bold text-ellipsis overflow-hidden max-w-[150px] max-h-[40px]" v-tippy="{ content: `${$store.state.login?.userName.toUpperCase()}` }"> {{ $store.state.login?.userName.toUpperCase() }} </p>
                            <p>+91 {{ currentMobileNo }}</p>
                        </div>
                    </div>
                    <div class="text-xs font-semibold self-end hyperLink cursor-pointer opacity-70" @click="backToLogin()">
                        Switch
                    </div>
                </div>
            </div>
                <div class="flex flex-col items-center mb-4">
                    <p class="text-xs primary-color mb-2">Scan the QR code on authenticator app</p>
                    <img :src="getScannerData?.scanImge" alt="qrcode" class="h-auto max-w-[50%]">
                    <div class="font-medium cursor-pointer hyperLink hover:text-blue-500 opacity-70"
                        @click="copySecretKey()" v-tippy="{ content: this.copyMsg, hideOnClick: false,}">Can't scan? Copy the key.</div>
                    <p class="secondary-color text-xs mt-4">Once scanned, the app should give you a 6 digit TOTP. Enter it
                        below.</p>
                </div>
                <label :for="`${stage}_input`" class="block text-sm font-medium primary-color">TOTP</label>
                <div class="mt-1 flex loginRadius shadow-sm">
                    <!-- <div class="relative flex flex-grow items-stretch focus-within:z-10">
                        <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-[6px]">
                            <span class="bg-[#F4F0FC] loginRadius p-2">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                    stroke="currentColor" class="w-4 h-4 violet-color">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
                                </svg>
                            </span>
                        </div>
                        <input ref="totp" type="text" maxlength="6" v-model="totp" name="totp" :id="`${stage}_input`"
                            autocomplete="off" class="text-base block w-full loginRadius  border-gray-200 pl-12 leading-6 "
                            placeholder="Enter your 6 digit TOTP" :class="{ 'is-invalid': submitted  }"
                            @input="digitKeyOnly($event, 'totp'); resetError()">
                    </div> -->
                    <div style="display: flex; flex-direction: row">
                        <v-otp-input
                        ref="totp"
                        name="totp"
                        :id="`${stage}_input`"
                        class="space-x-2 focus:outline-none"
                        v-model:value="totp"
                        input-classes="otp-input"
                        separator=""
                        :num-inputs="6"
                        :should-auto-focus="true"
                        input-type="tel"
                        :conditionalClass="['one', 'two', 'three', 'four', 'five', 'six']"
                        :placeholder="['', '', '', '', '', '']"
                        @click="resetError"
                        @change="resetError"
                        />
                    </div>
                </div>
            </div>
            <div class="flex flex-col items-center">
                <button :id="`${stage}_submit_btn`" :disabled="loader" type="submit" :class="{ 'loading': loader }"
                    class="flex w-full justify-center loginRadius border 
                                        border-transparent violet-bg py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-[#6f23f2] 
                                        focus:outline-none disabled:bg-gray-100 disabled:primary-color disabled:cursor-not-allowed min-h-[38px]">
                    <span v-if="!loader">Next</span>
                    <svg v-if="loader" aria-hidden="true" role="status"
                        class="inline mr-3 w-5 h-5 secondary-color animate-spin" viewBox="0 0 100 101" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="#E5E7EB"></path>
                        <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentColor"></path>
                    </svg>
                </button>
                
                <!-- <div class="flex mt-2 justify-center text-xs">
                    <div class="text-center">Back to <a
                            class="font-medium cursor-pointer hyperLink hover:text-blue-500 opacity-70"
                            @click="backToLogin()">Login</a></div>
                </div> -->
            </div>
            <div v-if="stage == 'totp'">
                <div :class="{ 'hyperLink': !isProblemWithTotp, 'primary-color': isProblemWithTotp }"
                    @click="isProblemWithTotp = true"
                    class="text-center font-medium cursor-pointer  hover:text-blue-500 opacity-70">Problem with External
                    TOTP?</div>
                <div v-if="isProblemWithTotp" class="flex justify-center mt-2">
                    <div><span class="font-medium cursor-pointer hyperLink hover:text-blue-500 opacity-70"
                            @click="loginWithOTP('resend')">Mobile OTP</span></div>
                    <!-- <div><span class="font-medium cursor-pointer hyperLink hover:text-blue-500 opacity-70"
                            @click="resetTotp()">Reset TOTP</span></div> -->
                </div>
            </div>
            
            <div class="h-2 mt-4">
                    <loginmessage cls="error text-left" v-show="!loader && submitted && errorMessage != ''">
                        {{ errorMessage }}
                    </loginmessage>
                </div>
        </form>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    data() {
        return {
            totp: '',
            isProblemWithTotp: false,
            copyMsg: 'Copy Key'
        }
    },
    computed: {
        ...mapGetters('login', ["stage", "loader", "submitted", "currentMobileNo", "errorMessage", "getScannerData"])
    },
    methods: {
        ...mapActions('login', ['loginWithOTP']),
        backToLogin() {
            this.$store.dispatch('login/backToLogin');
            this.mobileNo = this.currentMobileNo;
            this.passHide = 'Hide'
        },
        async handleSubmit() {
            this.$store.commit('login/setSubmitted', true)
            if (this.totp != '' && !this.loader) {
                await this.$store.dispatch('login/enableTotp', { totp: this.totp, mobileNo: this.currentMobileNo })
            }else if(this.totp == '') {
                this.$store.commit('login/setErrorMessage', 'Enter the TOTP')
                return false
            }
        },
        resetError() {
            this.$store.commit('login/setErrorMessage', null)
            this.$store.commit('login/setSubmitted', false)
        },
        resetTotp() {
            this.$store.commit('login/setStage', 'enableTotp')
            this.$store.dispatch('login/registerTotp')
        },
        copySecretKey() {
            navigator.clipboard.writeText(this.getScannerData?.secKey);
            this.copyMsg = 'Key Copied'
            setTimeout(() => {
                this.copyMsg = 'Copy Key'
            }, 1300);
        },
        digitKeyOnly(event, type) {
            // Allow only numeric values in the input field
            event.target.value = event.target.value?.toString()?.replace(/[^0-9]/g, '')
            if (type)
                this[type] = event.target.value
        },
    },
    created() {
        // this.$nextTick(() => {
        //     if (this.$refs.totp) {
        //         this.$refs.totp.focus();
        //     }
        // });
    }
}
</script>